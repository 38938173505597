.main-content{
  height: 80vh;
  overflow-y: scroll;
}

.backgroundImageHomePage {
  background-repeat: no-repeat;
  background-size: cover;
  height: 91vh;
  width: 100vw; 
  position: absolute;
  left: 0;
  background-image: url('./images/homePage.jpg');
}

.borderedName {
  position: absolute;
  margin: auto;
  top: 50%;
  left: 25%;
  width: 50%;
  color: white;
  text-align: center;
}

.borderedName hr.firsthr {
  background: url('./images/tspice.png') no-repeat top center;
  background-size: contain;
  height: 18px;
  border: 0;
  position: relative;
  opacity: 1;
}

.borderedName hr.firsthr:before,
.borderedName hr.firsthr:after {
  content: '';
  position: absolute;
  background: #d7d7d7;
  height: 2px;
  top: 8px;
}

.borderedName hr.firsthr:before {
  left: 0;
  right: 50%;
  margin-right: 10px;
}

.borderedName hr.firsthr:after {
  right: 0;
  left: 50%;
  margin-left: 10px;
}

.glyphicon.glyphicon-dot:before {
  content: "\25cf";
  font-size: 1em;
}